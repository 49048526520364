import React, { useState, useEffect,useCallback } from 'react';

// 模块化AI处理
const aiModules = {
  openai: async (prompt, aiConfig) => {
    try {
      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${aiConfig.key}`,
        },
        body: JSON.stringify({
          model: 'gpt-3.5-turbo',
          messages: [
            {
              role: 'user',
              content: prompt,
            },
          ],
        }),
      };

      const response = await fetch(aiConfig.url, requestOptions);
      const data = await response.json();

      if (data.choices && data.choices.length > 0 && data.choices[0].message && data.choices[0].message.content) {
        return data.choices[0].message.content.trim();
      } else {
        throw new Error("Failed to process content with AI");
      }
    } catch (error) {
      throw new Error("Error processing memo with AI: " + error.message);
    }
  }
};

const TomatoTimer = ({ onTimerComplete }) => {
  const [memoContent, setMemoContent] = useState('');
  const [error, setError] = useState('');
  const [isSaving, setIsSaving] = useState(false);
  const [aiService, setAiService] = useState('');
  const [aiConfig, setAiConfig] = useState({ url: '', key: '' });
  const [memosConfig, setMemosConfig] = useState({ url: '', token: '' });
  

  useEffect(() => {
    const settings = JSON.parse(localStorage.getItem('settings'));
    if (settings) {
      setAiService(settings.aiService);
      setAiConfig(settings.aiConfig);
      setMemosConfig(settings.memosConfig);
    }
  }, []);

  const saveMemo = useCallback((processedContent) => {
    const createMemoUrl = memosConfig.url;
    const token = memosConfig.token;
    const memoData = {
      content: processedContent,
      visibility: 'private',
    };

    const requestOptions = {
      method: 'POST',
      headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
      },
      body: JSON.stringify(memoData),
    };

    fetch(createMemoUrl, requestOptions)
      .then(response => {
        if (!response.ok) throw new Error('Network response was not ok');
        return response.json();
      })
      .catch(error => {
        setError('Error creating memo: ' + error.message);
        console.error('Error creating memo:', error);
      });
  }, [memosConfig.url, memosConfig.token]);

  const handleSaveMemo = useCallback(async () => {
    setIsSaving(true);
    try {
      const myprompt = localStorage.getItem('myprompt');
      const prompt = `${myprompt}>>>${memoContent}`;
      const processedContent = await aiModules[aiService](prompt, aiConfig);
      onTimerComplete(memoContent, processedContent);
      saveMemo(memoContent);
    } catch (error) {
      setError(error.message);
    } finally {
      setIsSaving(false);
    }
  }, [memoContent, aiService, aiConfig, onTimerComplete, saveMemo]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if ((event.metaKey && event.key === 'Enter' && navigator.platform.includes('Mac')) ||
          (event.ctrlKey && event.key === 'Enter' && !navigator.platform.includes('Mac'))) {
        handleSaveMemo();
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleSaveMemo]);

  return (
    <div className="flex flex-col items-center pt-4 w-full">
      <textarea
        className="textarea bg-white border border-gray-300 rounded-lg w-full p-2 h-32"
        placeholder="记录下你的收获吧！"
        value={memoContent}
        onChange={(e) => setMemoContent(e.target.value)}
        disabled={isSaving}
      ></textarea>
      {error && <p className="text-red-500 text-xs italic mt-2">{error}</p>}
      <button
        className={`mt-4 text-white font-bold py-2 px-4 rounded ${isSaving ? 'bg-gray-400' : 'bg-blue-500 hover:bg-blue-700'}`}
        onClick={handleSaveMemo}
        disabled={isSaving}
      >
        {isSaving ? 'Loading...' : '记录下你的收获'}
      </button>
    </div>
  );
};

export default TomatoTimer;