import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css'; // Styles for the date picker

const Statistics = ({ memos }) => {
  const [selectedDate, setSelectedDate] = useState(new Date(new Date().setDate(new Date().getDate() - 1))); // Default to yesterday

  // Filter memos based on the selected date
  const memosForSelectedDate = memos.filter(memo => {
    const memoDate = new Date(memo.time);
    return memoDate.toDateString() === selectedDate.toDateString();
  });

  return (
    <div className="bg-gray-100 p-4 rounded-lg">
      <DatePicker
        selected={selectedDate}
        onChange={date => setSelectedDate(date)}
        inline
        className="w-full"
      />
      <div className="mt-4">
        {memosForSelectedDate.length > 0 ? (
          <ul className="divide-y divide-gray-200">
            {memosForSelectedDate.map((memo, index) => (
              <li key={index} className="py-2">
                <p className="text-sm font-medium text-gray-700">{new Date(memo.time).toLocaleString()}</p>
                <p className="text-gray-600">{memo.content}</p>
              </li>
            ))}
          </ul>
        ) : (
          <p className="text-center text-gray-500">没有记录</p>
        )}
      </div>
    </div>
  );
}

const Logs = () => {
  return <div>日志组件</div>;
}

const Settings = () => {
  const [tomatoTime, setTomatoTime] = useState(25); // Default to 25 minutes
  const [aiService, setAiService] = useState('official'); // Default to official AI service
  const [aiConfig, setAiConfig] = useState({});
  const [memosConfig, setMemosConfig] = useState({});

  useEffect(() => {
    // Check local storage for saved settings and populate inputs if available
    const savedSettings = localStorage.getItem('settings');
    if (savedSettings) {
      const parsedSettings = JSON.parse(savedSettings);
      setTomatoTime(parsedSettings.tomatoTime || 25);
      setAiService(parsedSettings.aiService || 'official');
      setAiConfig(parsedSettings.aiConfig || {});
      setMemosConfig(parsedSettings.memosConfig || {});
    }
  }, []);

  const saveSettings = () => {
    const settings = {
      tomatoTime,
      aiService,
      aiConfig,
      memosConfig
    };
    localStorage.setItem('settings', JSON.stringify(settings));
    alert('Settings saved successfully!');
  };

  return (
    <div className="bg-gray-100 p-4 rounded-lg">
      <div>
        <label className="block mb-2 font-bold text-gray-700">番茄钟时间（分钟）：</label>
        <input
          type="number"
          value={tomatoTime}
          onChange={e => setTomatoTime(e.target.value)}
          className="border border-gray-300 rounded-md px-3 py-2 w-full"
        />
      </div>
      <div className="mt-4">
        <label className="block mb-2 font-bold text-gray-700">AI 服务商：</label>
        <select
          value={aiService}
          onChange={e => setAiService(e.target.value)}
          className="border border-gray-300 rounded-md px-3 py-2 w-full"
        >
          <option value="official">官方功能</option>
          <option value="openai">OpenAI</option>
        </select>
        {aiService === 'openai' && (
          <div className="mt-4">
            <label className="block mb-2 font-bold text-gray-700">OpenAI URL：</label>
            <input
              type="text"
              value={aiConfig.url || ''}
              onChange={e => setAiConfig({ ...aiConfig, url: e.target.value })}
              className="border border-gray-300 rounded-md px-3 py-2 w-full"
            />
            <label className="block mb-2 font-bold text-gray-700 mt-2">OpenAI Key：</label>
            <input
              type="text"
              value={aiConfig.key || ''}
              onChange={e => setAiConfig({ ...aiConfig, key: e.target.value })}
              className="border border-gray-300 rounded-md px-3 py-2 w-full"
            />
          </div>
        )}
      </div>
      <hr className="my-4" />
      <div>
        <label className="block mb-2 font-bold text-gray-700">Memos URL：</label>
        <input
          type="text"
          value={memosConfig.url || ''}
          onChange={e => setMemosConfig({ ...memosConfig, url: e.target.value })}
          className="border border-gray-300 rounded-md px-3 py-2 w-full"
        />
        <label className="block mb-2 font-bold text-gray-700 mt-2">Memos Token：</label>
        <input
          type="text"
          value={memosConfig.token || ''}
          onChange={e => setMemosConfig({ ...memosConfig, token: e.target.value })}
          className="border border-gray-300 rounded-md px-3 py-2 w-full"
        />
      </div>
      <button
        onClick={saveSettings}
        className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 rounded mt-4 transition duration-300 ease-in-out"
      >
        保存
      </button>
    </div>
  );
}

const R = ({ memos }) => {
  const [activeComponent, setActiveComponent] = useState('statistics');

  const renderComponent = () => {
    switch (activeComponent) {
      case 'statistics':
        return <Statistics memos={memos} />;
      case 'logs':
        return <Logs />;
      case 'settings':
        return <Settings />;
      default:
        return <Statistics memos={memos} />;
    }
  }

  return (
    <div className="flex flex-col p-4 bg-white shadow-lg rounded-lg max-w-lg mx-auto">
      <div className="flex justify-between mb-4 space-x-2">
        <button className="flex-1 bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 rounded transition duration-300 ease-in-out" onClick={() => setActiveComponent('statistics')}>
          统计
        </button>
        <button className="flex-1 bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 rounded transition duration-300 ease-in-out" onClick={() => setActiveComponent('logs')}>
          日志
        </button>
        <button className="flex-1 bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 rounded transition duration-300 ease-in-out" onClick={() => setActiveComponent('settings')}>
          设置
        </button>
      </div>
      {renderComponent()}
    </div>
  );
}

export default R;
