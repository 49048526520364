import React, { useState, useEffect } from 'react';
import L1 from './L1'; // 导入L1组件
import L2 from './L2'; // 导入L2组件
import R from './R'; // 导入R组件

const App = () => {
  // 加载备忘录数据
  const [memos, setMemos] = useState(() => {
    const savedMemos = localStorage.getItem('memos');
    return savedMemos ? JSON.parse(savedMemos) : [];
  });



  // 监听memos的变化，并更新本地存储
  useEffect(() => {
    localStorage.setItem('memos', JSON.stringify(memos));
  }, [memos]);

   // prompt初始化
  useEffect(() => {
    const prompt = '阅读以下内容，使用中文输出简洁有力激励内容，使我保持热情！文字风格幽默大气，合理的使用emoji美化效果！以hohoho结尾，不要超出50个汉字！！！';
    localStorage.setItem('myprompt', JSON.stringify(prompt));
  }, []);

  return (
    <div className="min-h-screen bg-gray-100 flex flex-wrap justify-center">
      <div className="w-full sm:w-2/3 md:w-1/2 lg:w-1/3 xl:w-1/4 p-4">
        <L1 setMemos={setMemos} />
        <L2 memos={memos} />
      </div>
      <div className="w-full sm:w-1/3 md:w-1/2 lg:w-1/3 xl:w-1/4 p-4">
        <R memos={memos} />

      </div>
    </div>
  );
};

export default App;
