import React, { useState, useEffect } from 'react';
import TomatoTimer from './TomatoTimer';

const L1 = ({ setMemos }) => {
  const [totalTime, setTotalTime] = useState(() => {
    const savedTotalTime = localStorage.getItem('settings');
    return savedTotalTime ? JSON.parse(savedTotalTime).tomatoTime * 60 : 25 * 60;
  });

  const [timer, setTimer] = useState(totalTime);
  const [isActive, setIsActive] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const [startTime, setStartTime] = useState(null);
  const [progress, setProgress] = useState(100);
  const [showTimer, setShowTimer] = useState(false);

  useEffect(() => {
    document.title = isActive ? '番茄钟进行中' : '番茄钟已停止';
    const favicon = document.querySelector("link[rel='icon']");
    if (favicon) {
      favicon.href = isActive && !isPaused
        ? `${process.env.PUBLIC_URL}/progress.png`
        : `${process.env.PUBLIC_URL}/completed.png`;
    }
  
    let interval = null;
    if (isActive && !isPaused) {
      if (!startTime) {
        setStartTime(Date.now());
      }
      interval = setInterval(() => {
        const timeElapsed = Math.floor((Date.now() - startTime) / 1000);
        const newTimer = totalTime - timeElapsed;
        setTimer(newTimer);
        setProgress((newTimer / totalTime) * 100);
  
        if (newTimer <= 0) {
          clearInterval(interval);
          setIsActive(false);
          setIsPaused(false);  // 确保暂停状态被重置
          setTimer(totalTime); // 重置计时器到初始总时间
          setProgress(100);
          setShowTimer(true);
          document.title = '番茄钟完成';
          if (favicon) {
            favicon.href = `${process.env.PUBLIC_URL}/completed.png`;
          }
          new Audio(`${process.env.PUBLIC_URL}/completed.wav`).play();
        }
      }, 1000);
    } else {
      clearInterval(interval);
      if (!isActive) {
        setProgress(100);
        setTimer(totalTime); // 确保在停止时重置计时器
      }
    }
  
    return () => clearInterval(interval);
  }, [isActive, isPaused, startTime, totalTime]);
  
  const toggleTimer = () => {
    setIsActive(prevState => !prevState);
    if (!isActive) {
      setIsPaused(false);
      setStartTime(Date.now() - (totalTime - timer) * 1000);
      setShowTimer(false);
    } else {
      setIsPaused(prevPaused => !prevPaused);
    }
  };

  const onTimerComplete = (originalContent, aiProcessedContent) => {
    const newMemos = [
        { content: originalContent, time: new Date().toISOString(), type: 'original' },
        { content: aiProcessedContent, time: new Date().toISOString(), type: 'processed' }
    ];
    setMemos(prevMemos => [...prevMemos, ...newMemos]);
    setShowTimer(false);
};


  const formatTime = () => {
    const minutes = Math.floor(timer / 60);
    const seconds = timer % 60;
    return `${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;
  };

  return (
    <div className="flex flex-col items-center">
      <button
        className="w-full bg-blue-500 text-white font-bold py-2 px-4 rounded relative overflow-hidden"
        onClick={toggleTimer}
      >
        <div className="absolute top-0 right-0 bg-red-500 h-full" style={{ width: `${progress}%` }}></div>
        <span className="relative z-10">
          {isActive && !isPaused ? formatTime() : '开始番茄'}
        </span>
      </button>
      {showTimer && (
        <TomatoTimer onTimerComplete={onTimerComplete} />
      )}
    </div>
  );
};

export default L1;
