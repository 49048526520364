import React, { useState } from 'react';

const L2 = ({ memos }) => {
  // 初始时没有展开的memo（null），我们将在第一次渲染后设置第一个processed为展开
  const [expandedId, setExpandedId] = useState(null);

  // 获取今天的日期（午夜开始）
  const startOfToday = new Date();
  startOfToday.setHours(0, 0, 0, 0);

  // 筛选并排序 memos：只显示今天的 memos，并按时间降序排序
  const filteredMemos = memos
    .filter(memo => new Date(memo.time) >= startOfToday)
    .sort((a, b) => new Date(b.time) - new Date(a.time));

  // 将 memos 分为 original 和 processed 组
  const memoPairs = filteredMemos.reduce((pairs, memo) => {
    if (memo.type === 'original') {
      pairs.push([memo]);
    } else if (memo.type === 'processed' && pairs.length && pairs[pairs.length - 1].length === 1) {
      pairs[pairs.length - 1].push(memo);
    }
    return pairs;
  }, []);

  // 切换展开/收起的状态
  const toggleExpand = (index) => {
    setExpandedId(expandedId === index ? null : index);
  };

  // 在组件挂载后，设置第一个processed为展开
  React.useEffect(() => {
    if (memoPairs.length > 0 && memoPairs[0].length === 2) {
      setExpandedId(0); // 如果第一组有processed memo，将其设置为展开
    }
  }, []); // 依赖数组为空，这个effect只在挂载后运行一次

  return (
    <div className="mt-4 p-4 bg-white shadow rounded-lg">
      {memoPairs.length === 0 ? (
        <div className="text-center text-gray-500">没有记录</div>
      ) : (
        <ul className="divide-y divide-gray-200">
          {memoPairs.map((pair, index) => (
            <li key={index} className="py-4">
              <div className="flex justify-between items-center">
                <div className="text-sm text-gray-600">{new Date(pair[0].time).toLocaleString()} (original)</div>
                {pair[1] && (
                  <button onClick={() => toggleExpand(index)}>
                    {expandedId === index ? '▼' : '▶'}
                  </button>
                )}
              </div>
              <div className="mt-1 text-gray-900">{pair[0].content}</div>
              {pair[1] && expandedId === index && (
                <div>
                  <div className="text-sm text-gray-600">{new Date(pair[1].time).toLocaleString()} (processed)</div>
                  <div className="mt-1 text-gray-900">{pair[1].content}</div>
                </div>
              )}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}

export default L2;
